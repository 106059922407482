/// <reference path="../libs/jquery/jquery.d.ts" />
/// <reference path="../utils/DateUtil.ts" />

namespace project {
    export class NewsIndex {
        $newsList: JQuery;
        constructor() {
            this.$newsList = $(".content_news");
            this.$newsList.each((i, value) => {
                let dateArray: string[] = $(value).find(".content_date").text().split(".");
                let upDate = new Date();
                upDate.setFullYear(+dateArray[0]);
                let month = +dateArray[1] - 1;
                upDate.setMonth(month);
                upDate.setDate(+dateArray[2]);

                let nowDate = new Date();
                let nowYear = nowDate.getFullYear();
                let nowMonth = nowDate.getMonth();
                let nowDay = nowDate.getDate();
                let diffDate = utils.compareDate(nowYear, nowMonth + 1, nowDay, upDate.getFullYear(), upDate.getMonth() + 1, upDate.getDate());
                if (diffDate < 7) {
                    $(value).addClass("new_icon");
                }
            });
        }
    }
}
