/// <reference path="../libs/jquery/jquery.d.ts" />
/// <reference path="../libs/velocity-animate/velocity-animate.d.ts" />

namespace project {
    export class All {
        $win: JQuery;
        $header: JQuery;
        $sHeader: JQuery;
        $mainvisual: JQuery;
        $wrap_outer: JQuery;
        $navBtList: JQuery;
        $btArrow: JQuery;
        $btMail: JQuery;
        $btPagetopArea: JQuery;
        $btPagetop: JQuery;
        $btPagetopLine: JQuery;
        constructor() {
            this.$win = $(window);
            this.$header = $(".header_outer");
            this.$sHeader = $(".s_header_outer");
            this.$mainvisual = $(".mainvisual");
            this.$wrap_outer = $(".wrap_outer");
            this.$btPagetopArea = $(".bt_pagetop_area");
            this.$btPagetop = $(".bt_pagetop");
            this.$btPagetopLine = $(".bt_pagetop_line");
            this.$navBtList = $(".header_nav a, .s_header_nav a");
            this.$btArrow = $(".bt_arrow");
            this.$btMail = $(".bt_mail");
            this.setNavHoverEvent();
            this.setBtMoreHoverEvent();
            this.setBtMailHoverEvent();
            this.setPagetopEvent();
            this.scrollCheck(null);
            this.$win.on("scroll", $.proxy(this.scrollCheck, this));
        }

        scrollCheck(event: JQuery): void {
            if (this.$win.scrollTop() > 50) {
                this.$header.css({"display": "none"});
                this.$sHeader.css({"display": "block"});
                if (this.$mainvisual.length !== 0) {
                    this.$mainvisual.css({paddingTop: "115px"});
                } else {
                    this.$wrap_outer.css({paddingTop: "115px"});
                }
            } else {
                this.$header.css({"display": "block"});
                this.$sHeader.css({"display": "none"});
                if (this.$mainvisual.length !== 0) {
                    this.$mainvisual.css({paddingTop: 0});
                } else {
                    this.$wrap_outer.css({paddingTop: 0});
                }
            }
        }
        setBtMailHoverEvent(): void {
            this.$btMail.hover(function() {
                $(this).find("span").velocity({
                    translateX: "-=6px",
                    translateY: "-=8px",
                    rotateZ: "-=25deg"
                }, {
                    duration: 300,
                    easing: "easeInQuad"
                });
            }, function() {
                $(this).find("span").velocity({
                    translateX: "+=6px",
                    translateY: "+=8px",
                    rotateZ: "+=25deg"
                }, {
                    duration: 150,
                    easing: "easeOutQuad"
                });
            });
        }
        setBtMoreHoverEvent(): void {
            this.$btArrow.on("mouseenter", function () {
                $(this).find("span").velocity({
                    translateX: "-=10px"
                }, {
                    duration: 0
                }).velocity({
                    translateX: "+=10px"
                }, {
                    duration: 300,
                    easing: "easeOutQuad"
                });
            });
        }
        setNavHoverEvent(): void {
            this.$navBtList.on("mouseenter", function() {
                $(this).find("span").velocity({
                    translateY: "-=15px"
                }, {
                    duration: 400,
                    easing: "easeOutQuad"
                }).velocity({
                    translateY: "+=15px"
                }, {
                    duration: 600,
                    easing: "easeOutBounce"
                });
            });
        }
        setPagetopEvent(): void {
            this.$btPagetopArea.on("click", $.proxy(this.onPagetop, this));
        }
        onPagetop(event: JQueryEventObject): void {
            this.$btPagetopArea.off("click");
            this.$btPagetop.velocity({
                translateY: "-=250px"
            }, {
                duration: 800,
                easing: "ease-in",
                complete: () => {
                    this.pageTopAnimate();
                }
            });
            this.$btPagetopLine.velocity({
                opacity: 0
            }, {
                duration: 100,
                easing: "ease-in"
            });
        }
        pageTopAnimate(): void {
            let speed = 800;
            let pos = $("html").offset().top;
            $("body").velocity("scroll", {
                duration: speed,
                easing: "ease-in-out",
                complete: () => {
                    this.onPagetopReset();
                }
            });
        }
        onPagetopReset(): void {
            this.$btPagetop.velocity({
                translateY: "0px"
            }, {
                duration: 0,
                delay: 800
            });
            this.$btPagetopLine.velocity({
                opacity: 1
            }, {
                duration: 0,
                delay: 800
            });
            this.setPagetopEvent();
        }
    }
}
