namespace utils {
    /**
    * 年月日を8桁の文字列に変換します
    * @param {date}
    * @return {string}
    */
    export function dateFormat(date: Date): string {
        return date.getFullYear() + ("0" + ( date.getMonth() + 1)).slice(-2) + ("0" + date.getDate()).slice(-2);
    }

    /**
    * addDays後、前の年月日を取得します
    * @param {number}
    * @param {number}
    * @param {number}
    * @param {number}
    * @return {Date}
    */
    export function computeDate(year, month, day, addDays): Date {
        let dt: Date = new Date(year, month - 1, day);
        let baseSec = dt.getTime();
        let addSec = addDays * 86400000; //日数 * 1日のミリ秒数
        let targetSec = baseSec + addSec;
        dt.setTime(targetSec);
        return dt;
    }

    /**
    * 指定された日付が有効な日付かどうかをチェックします
    * @param {number} 年
    * @param {number} 月
    * @param {number} 日
    * @return {boolean}
    */
    export function checkDate(year: number, month: number, day: number): boolean {
        var dt: Date = new Date(year, month, day);
        if (dt == null || dt.getFullYear() !== year || dt.getMonth() + 1 !== month || dt.getDate() !== day) {
            return false;
        }
        return true;
    }

    /**
    * うるう年（閏年）の判定
    * @param {number} 年
    * @return {boolean}
    */
    export function checkLeapyear(year: number): boolean {
        return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    }

    /**
    * 年月を指定して月末日を求める関数
    * @param {number} 年
    * @param {number} 月
    * @return {number}
    */
    export function getMonthEndDay(year: number, month: number): number {
        //日付を0にすると前月の末日を指定したことになります
        //指定月の翌月の0日を取得して末日を求めます
        //そのため、ここでは month - 1 は行いません
        let dt: Date = new Date(year, month, 0);
        return dt.getDate();
    }

    /**
    * 年月日と加算月からnヶ月後、nヶ月前の日付を求める
    * year 年
    * month 月
    * day 日
    * addMonths 加算月。マイナス指定でnヶ月前も設定可能
    */
    export function computeMonth(year: number, month: number, day: number, addMonths: number): Date {
        month += addMonths;
        let endDay = getMonthEndDay(year, month); //ここで、前述した月末日を求める関数を使用します
        if (day > endDay) {
            day = endDay;
        }
        let dt: Date = new Date(year, month - 1, day);
        return dt;
    }

    /**
    * 2つの日付の差を求める関数
    * year1 1つのめ日付の年
    * month1 1つめの日付の月
    * day1 1つめの日付の日
    * year2 2つのめ日付の年
    * month2 2つめの日付の月
    * day2 2つめの日付の日
    */
    export function compareDate(year1: number, month1: number, day1: number, year2: number, month2: number, day2: number): number {
        let dt1: Date = new Date(year1, month1 - 1, day1);
        let dt2: Date = new Date(year2, month2 - 1, day2);
        let diff = +dt1 - +dt2;
        let diffDay = diff / 86400000; //1日は86400000ミリ秒
        return diffDay;
    }

    /**
    * 任意の日付の曜日を日本語で取得します
    * @param {number} 年
    * @param {number} 月
    * @param {number} 日
    * @return {string} 曜日
    */
    export function getJPDay(year: number, month: number, day: number): string {
        let week: string[] = ["日", "月", "火", "水", "木", "金", "土"];
        let dt: Date = new Date(year, month - 1, day);
        let dayOfWeek = week[dt.getDay()];
        return dayOfWeek;
    }

    /**
    * 任意の年月の第n曜日の日付を求める関数
    * @param {number} year 年
    * @param {number} month 月
    * @param {number} num 何番目の曜日か、第1曜日なら1。第3曜日なら3
    * @param {number} dayOfWeek 求めたい曜日。0〜6までの数字で曜日の日〜土を指定する
    * @return {Date}
    */
    export function getWhatDayOfWeek(year: number, month: number, num: number, dayOfWeek: number): Date {
        let firstDt = new Date(year, month - 1, 1);
        let firstDayOfWeek = firstDt.getDay(); //指定した年月の1日の曜日を取得
        let day = dayOfWeek - firstDayOfWeek + 1;
        if (day <= 0) {
            // 1週間を足す
            day += 7;
        }
        let dt = new Date(year, month - 1, day);
        let msTime = dt.getTime();
        msTime += (86400000 * 7 * (num - 1)); //n曜日まで1週間を足し込み
        dt.setTime(msTime);
        return dt;
    }
}
